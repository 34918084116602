<template>
  <a-row :gutter="50">
    <a-col :md="24" :xs="24">
      <Suspense>
        <template #default>
          <ActivityContent />
        </template>
        <template #fallback>
          <sdCards headless>
            <a-skeleton active :paragraph="{ rows: 10 }" />
          </sdCards>
        </template>
      </Suspense>
    </a-col>
  </a-row>
</template>
<script>
import { defineAsyncComponent } from 'vue';
const ActivityContent = defineAsyncComponent(() => import('./ActivityContent'));

const Activity = {
  name: 'Activity',
  components: { ActivityContent },
  setup() {

  }
};

export default Activity;
</script>
