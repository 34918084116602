<template>
  <div>
    <Main>
      <a-row :gutter="25">
        <a-col :xs="24">

          <SupportContentWrap>
            <div class="sDash-support-container">
              <sdHeading id="shipment-overview-heading" as="h2">Shipment overview</sdHeading>
<!--              Insert the shit here... -->
              <a-row :gutter="20">
                <a-col :xxl="12" :xl="24" :md="24" :xs="24">
                  <div class="about-project-wrapper">
                    <sdCards title="Delivery Details">
                      <div class="about-project">
                        <div>
                          <strong>Shipment title</strong>
                          <p>{{shipment.title}}</p>
                        </div>
                        <div>
                          <strong>Shipment code</strong>
                          <p>{{shipment.code}}</p>
                        </div>
                        <div>
                          <strong>Shipment description</strong>
                          <p>{{shipment.description}}</p>
                        </div>

                        <div>
                          <strong>Expected delivery date</strong>
                          <p>{{shipment?.dateOfDelivery ?? "N/A"}}</p>
                        </div>
                      </div>
                    </sdCards>
                  </div>
                </a-col>
                <a-col :xxl="12" :xl="24" :md="24" :xs="24">
                  <Activity></Activity>
                </a-col>
              </a-row>

              <a-row>
                <a-col :md="24">
                  <SupportForm></SupportForm>
                </a-col>
              </a-row>

              <div class="sDash_faq-block">
                <sdCards headless title="Frequently Asked Questions">
                  <FaqWrapper>
                    <a-collapse :bordered="false" default-active-key="1">
                      <a-collapse-panel :showArrow="true" header="What do I do if my package has not been delivered?" key="1">
                        <p>
                          Make use of the contact form on this page. We will reach out to the shipment partner on your behalf to follow up about packages.
                        </p>
                        <sdHeading as="h4">Was this article helpful?</sdHeading>
                        <div class="panel-actions">
                          <sdButton :outlined="true" type="success">
                            <sdFeatherIcons type="smile" size="14" />
                            <span>Yes</span>
                          </sdButton>
                          <sdButton :outlined="true" type="warning">
                            <sdFeatherIcons type="frown" size="14" />
                            <span>No</span>
                          </sdButton>
                        </div>
                      </a-collapse-panel>
                      <a-collapse-panel
                          :showArrow="true"
                          header="How to use SCSS variables to build custom color?"
                          key="2"
                      >
                        <p>
                          Many support queries and technical questions will already be answered in supporting
                          documentation such as FAQ&rsquo;s and comments from previous buyers. Anim pariatur cliche
                          reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                          aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                          Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
                          assumenda shoreditch et.
                        </p>
                        <sdHeading as="h4">Was this article helpful?</sdHeading>
                        <div class="panel-actions">
                          <sdButton :outlined="true" type="success">
                            <sdFeatherIcons type="smile" size="14" />
                            <span>Yes</span>
                          </sdButton>
                          <sdButton :outlined="true" type="warning">
                            <sdFeatherIcons type="frown" size="14" />
                            <span>No</span>
                          </sdButton>
                        </div>
                      </a-collapse-panel>
                      <a-collapse-panel :showArrow="true" header="How long does it take to download updates?" key="3">
                        <p>
                          Many support queries and technical questions will already be answered in supporting
                          documentation such as FAQ&rsquo;s and comments from previous buyers. Anim pariatur cliche
                          reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                          aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                          Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
                          assumenda shoreditch et.
                        </p>
                        <sdHeading as="h4">Was this article helpful?</sdHeading>
                        <div class="panel-actions">
                          <sdButton :outlined="true" type="success">
                            <sdFeatherIcons type="smile" size="14" />
                            <span>Yes</span>
                          </sdButton>
                          <sdButton :outlined="true" type="warning">
                            <sdFeatherIcons type="frown" size="14" />
                            <span>No</span>
                          </sdButton>
                        </div>
                      </a-collapse-panel>
                    </a-collapse>
                  </FaqWrapper>
                </sdCards>
              </div>
            </div>
          </SupportContentWrap>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {Main} from '../../styled';
import {SupportContentWrap, FaqWrapper} from './style';
import Activity from "./Activity"
import SupportForm from "./Form";
import {defineComponent, ref} from 'vue';
import {DataService} from "@/config/dataService/dataService";
import {useRoute, useRouter} from "vue-router";

const ShipmentDetails = defineComponent({
  name: 'Faqs',
  components: {
    Main,
    SupportContentWrap,
    FaqWrapper,
    Activity,
    SupportForm
  },
  setup() {
    let shipment = ref({});
    let notifications = ref([]);

    return {
      shipment,
      notifications
    }
  },
  mounted() {
    const route = useRoute()
    const router = useRouter();

    let shipmentId = route.query.shipmentId;
    let token = route.query.token;

    DataService.get(`portal/shipment?guid=${shipmentId}&token=${token}`).then(result => {
      this.shipment = result.data;
    }).catch(() => {
      router.push('/request-access')
    })
  }
});

export default ShipmentDetails;
</script>
