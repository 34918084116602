import Styled from 'vue3-styled-components';

const FaqWrapper = Styled.div`
  .ant-card{
    .ant-card-body{
      h1{
        font-weight: 500;
      }
    }
  }
  .ant-collapse{
    margin-top: 25px;
    &.ant-collapse-borderless{
      background: #fff;
    }
    &.ant-collapse-icon-position-left{
      .ant-collapse-header{
        color: ${({ theme }) => theme['dark-color']} !important;
      }
    }
  }
  .ant-collapse-item{
    border: 1px solid ${({ theme }) => theme['border-color-light']} !important;
    margin-bottom: 5px;
    &.ant-collapse-item-active{
      box-shadow: 0px 15px 40px ${({ theme }) => theme['light-color']}15;
    }
    .ant-collapse-header{
      font-weight: 500;
      font-size: 15px;
      background-color: #fff;
      padding: 18px 25px !important;
      border-radius: 5px !important;
      @media only screen and (max-width: 575px){
        padding: ${({ theme }) => (!theme.rtl ? '15px 45px 15px 15px' : '15px 15px 15px 45px')} !important;
      }
      .ant-collapse-arrow{
        ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: auto !important;
        ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 25px !important;
        top: 22px !important;
        transform: translateY(0) !important;
      }
    }
  }

  .ant-collapse-content{
    background-color: #fff !important;
    box-shadow: 0 15px 40px ${({ theme }) => theme['light-color']}15;
    .ant-collapse-content-box{
      border-top: 1px solid ${({ theme }) => theme['border-color-light']} !important;
      padding: 20px 25px 30px !important;
      P{
        font-size: 15px;
        margin-bottom: 35px;
        line-height: 1.667;
        color: ${({ theme }) => theme['gray-color']};
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 12px;
        color: ${({ theme }) => theme['dark-color']};
      }
      .panel-actions{
        display: flex;
        button{
          height: 36px;
          padding: 0 15px;
          &:not(:last-child){
            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
          }
        }
      }
    }
  }
`;

const SupportContentWrap = Styled.div`

#shipment-overview-heading {
    margin-bottom: 20px;
}
  // background-color: #fff;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 20px #9299B810;
  padding: 0 0 50px 0;
  @media only screen and (max-width: 1599px){
    padding: 50px 0 20px 0;
  }
  @media only screen and (max-width: 1199px){
    margin-bottom: 30px;
  }
  .sDash-support-container{
    max-width: 1110px;
    margin: 0 auto;
    @media only screen and (max-width: 1599px){
      max-width: 990px;
    }
    @media only screen and (max-width: 1399px){
      max-width: 790px;
    }
    @media only screen and (max-width: 1150px){
      max-width: 100%;
      padding: 30px
    }
  }
  .sDash-support-link-item{
    max-width: 350px;
    margin: 0 auto 30px;
    padding: 30px;
    text-align: center;
    border-radius: 6px;
    transiiton: .35s;
    border: 1px solid ${({ theme }) => theme['border-color-light']};
    &:hover{
      box-shadow: 0 15px 25px #9299BB15;
      border-color: #fff;
    }
    .sDash-support-link-item__icon{
      height: 80px;
      width: 80px;
      margin: 0 auto 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      &.primary{
        background-color: ${({ theme }) => theme['primary-color']};
      }
      &.success{
        background-color: ${({ theme }) => theme['success-color']};
      }
      &.info{
        background-color: ${({ theme }) => theme['info-color']};
      }
    }
    .sDash-support-link-item__title{
      font-size: 20px;
      font-weight: 500;
      color: ${({ theme }) => theme['dark-color']};
    }
    .sDash-support-link-item__content{
      .btn-link{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        background-color: #EFEFFE;
        border-radius: 20px;
        margin: 36px auto 0;
        font-size: 15px;
        padding: 0 24.5px;
        width: fit-content;
      }
    }
  }
  .sDash_faq-block{
    // margin-top: 70px;
    @media only screen and (max-width: 1150px){
      margin-top: 20px;
    }
    .ant-card{
      border: 1px solid ${({ theme }) => theme['border-color-normal']};
      .ant-card-body{
        h1{
          color: ${({ theme }) => theme['dark-color']};
        }
      }
    }
    .ant-collapse-item {
      &.ant-collapse-item-active{
        box-shadow: 0 0;
      }
      .ant-collapse-header{
        background-color: #fff !important;
        padding: 19.5px 25px !important
        .ant-collapse-arrow{
          color: #9299B8;
        }
      }
      .ant-collapse-content{
        box-shadow: 0 0;
      }
    }
  }
  
  .about-project-wrapper{
    // min-height: 350px;
    border-radius: 10px;
    margin-bottom: 25px;
  }
  
`;

const ActivityContentWrapper = Styled.div`
    .ant-card-body{
        padding: 25px 0 !important;
    }
    .activity-list{
        margin: 0;
        .activity-list__single{
            padding: 12px 25px;
            display: flex;
            align-items: center;
            .activity-icon{
                width: 31px;
                height: 31px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #00000015;
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
                &.primary{
                    background-color: ${({ theme }) => theme['primary-color']}15;
                    color: ${({ theme }) => theme['primary-color']};
                }
                &.secondary{
                    background-color: ${({ theme }) => theme['secondary-color']}15;
                    color: ${({ theme }) => theme['secondary-color']};
                }
                &.success{
                    background-color: ${({ theme }) => theme['success-color']}15;
                    color: ${({ theme }) => theme['success-color']};
                }
                &.info{
                    background-color: ${({ theme }) => theme['info-color']}15;
                    color: ${({ theme }) => theme['info-color']};
                }
                &.danger{
                    background-color: ${({ theme }) => theme['danger-color']}15;
                    color: ${({ theme }) => theme['danger-color']};
                }
                &.warning{
                    background-color: ${({ theme }) => theme['warning-color']}15;
                    color: ${({ theme }) => theme['warning-color']};
                }
                i{
                    line-height: 0;
                }
            }
            .more{
                visibility: hidden;
            }
            &:hover{
                box-shadow: 0 15px 50px #88888820;
                .more{
                    visibility: visible;
                }
            }
            .activity-content{
                flex: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .activity-info{
                display: flex;
                align-items: center;
                img{
                    max-width: 40px;
                    border-radius: 50%;
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 20px;
                }
                p{
                    margin-bottom: 0;
                    font-size: 14px;
                    color: ${({ theme }) => theme['gray-color']};
                    .inline-text{
                        font-weight: 500;
                        display: inline;
                    }
                    .hour{
                        display: block;
                        margin-top: 3px;
                        color: ${({ theme }) => theme['extra-light-color']};
                    }
                }
            }
            .activity-more{
                svg,
                i{
                    color: ${({ theme }) => theme['extra-light-color']};
                }
            }
        }
    }
`;

const UpdatePopup = Styled.div`
    .ant-card{
        width: 320px !important;
        .ant-card-body{
            padding: 0 !important;

            .headerUpdate{
                border-radius: 8px 8px 0 0;
                padding: 13px 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                h4{
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                    margin-bottom: 0;
                }
                .action{
                    display: flex;
                    align-items: center;
                    width: 100px;
                    justify-content: flex-end;
                    color: #fff;
                    a{
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        min-width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        background-color: transparent;
                        padding: 0;
                        color: #fff;
                        transition: .3s;
                        &:hover{
                            background-color: #F4F5F715;
                        }
                        svg{
                            margin: 0;
                        }
                    }
                }
            }
            .bodyUpdate{
                padding: 20px;
                .event-info{
                    display: flex;
                    align-items: flex-start;
                    color: ${({ theme }) => theme['gray-color']};
                    &:last-child{
                        margin-bottom: 0;
                    }
                    strong{
                        margin-left: 6px;
                        ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 6px;
                        font-weight: 500;
                        color: ${({ theme }) => theme['dark-color']};
                    }
                    svg,
                    i{
                        color: ${({ theme }) => theme['light-color']};
                    }
                    svg,
                    i,
                    img{
                        margin: ${({ theme }) => (theme.rtl ? '4px 0 0 12px' : '4px 12px 0 0')};
                    }
                    label{
                        font-size: 13px;
                        color: ${({ theme }) => theme['light-color']};
                    }
                }
            }
        }
    }
`;

const VerticalFormStyleWrap = Styled.div`
    .ant-card{
        margin-bottom: 25px
    }

    .ant-input-affix-wrapper .ant-input-prefix svg{
        color: ${({ theme }) => theme['gray-color']};
    }
    .sDash_form-action{
        margin: -7.5px;
        button{
            font-size: 14px;
            font-weight: 500;
            border-radius: 6px;
            margin: 7.5px;
            padding: 6.4px 19px;
            &.ant-btn-light{
                height: 44px;
                background-color: #F1F2F6;
                border-color: #F1F2F6;
            }
        }
        .ant-form-item{
            margin-bottom: 25px !important;
        }
        .ant-btn-light{
            background-color: #F8F9FB;
        }
    }
    .ant-form-item{
        padding-bottom: 0;
    }
`;

export {
    FaqWrapper,
    SupportContentWrap,
    ActivityContentWrapper,
    UpdatePopup,
    VerticalFormStyleWrap
};
