<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical">
        <sdHeading as="h3" id="primary-heading"> Request <span class="color-primary">access</span></sdHeading>
        <div class="subheading">
          <small class="subheading">Request access to your shipment information, and the new link will sent to
            you.</small>
        </div>

        <a-form-item name="username" label="Email Address">
          <a-input type="email" v-model:value="formState.email" />
        </a-form-item>

        <a-form-item>
          <sdButton class="btn-signin" htmlType="submit" type="primary" size="large">
            {{'Request Access' }}
          </sdButton>
        </a-form-item>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { reactive, ref, defineComponent } from 'vue';
import { AuthWrapper } from './style';
import { useRouter } from 'vue-router';

const SignIn = defineComponent({
  name: 'SignIn',
  components: { AuthWrapper },
  setup() {
    const checked = ref(null);
    const router = useRouter();

    const handleSubmit = () => {
      router.push('/');
    };

    const onChange = (checked) => {
      checked.value = checked;
    };

    const formState = reactive({
      email: 'example@email.com',
      password: '1234565',
    });

    return {
      checked,
      handleSubmit,
      onChange,
      formState
    };
  },
});

export default SignIn;
</script>
